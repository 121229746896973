export class TransactionMessage {
	txType: 				string;
	chain: 					string;
	msg:						string;
	additionalData: { [key: string]: string };

	setTxType(txType: string) {
		this.txType = txType;
		return this;
	}

	setChain(chain: string) {
		this.chain = chain;
		return this;
	}

	setMessage(msg: string) {
		this.msg = msg;
		return this;
	}

	setAdditionalInfo(additionalInfo: { [key: string]: string }) {
		this.additionalData = additionalInfo;
		return this;
	}

	build(): TransactionMessage {
		const txnMessage: TransactionMessage = new TransactionMessage();
		txnMessage.txType 				= this.txType;
		txnMessage.chain 					= this.chain;
		txnMessage.msg 						= this.msg;
		txnMessage.additionalData = this.additionalData;

		return txnMessage;
	}

	buildJSON(): string {
		const txnMessage = this.build();
		return JSON.stringify(txnMessage);
	}
}
