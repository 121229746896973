// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {PageCodesEnum} from '../app/models/enums/page-code.enum';

export const environment = {
  production: true,
  modules: [

    // USER WALLET MANAGEMENT

    PageCodesEnum.USER_WALLET_MANAGEMENT,
    PageCodesEnum.WALLET_DETAILS,
    PageCodesEnum.SUBMISSIONS,
    PageCodesEnum.KYC,
    PageCodesEnum.KYC_SUBMISSIONS,
    PageCodesEnum.SUBMISSIONS_DETAIL,

    // DEVELOPMENT

    PageCodesEnum.DEVELOPMENT,
    PageCodesEnum.FORMS,
    PageCodesEnum.KYC_FORMS,
    PageCodesEnum.SCREENING_FORMS,
    PageCodesEnum.DEVELOPMENT_FORMS,
    PageCodesEnum.TEST_SUBMISSION,

    // ENVIRONMENT SETTINGS

    PageCodesEnum.ENVIRONMENT_SETTINGS,
    PageCodesEnum.RATES,
    PageCodesEnum.CURRENCY,
    PageCodesEnum.ADDRESSES,
    PageCodesEnum.EXCHANGE,
    // PageCodesEnum.DIRECTORS_PASSWORD,
    PageCodesEnum.FEES,
    PageCodesEnum.TABLE_DEFINE_FEE,
    PageCodesEnum.TRANSFER_TYPE,
    PageCodesEnum.FEE_TYPE,
    PageCodesEnum.MULTI_LEVEL_FEE,
    PageCodesEnum.CALCULATION,
    PageCodesEnum.ACCOUNT_TYPE,
    PageCodesEnum.USER_AA,
    PageCodesEnum.USER_BB,
    PageCodesEnum.USER_CC,
    PageCodesEnum.TIER,
    PageCodesEnum.KYC_LEVELS,
    PageCodesEnum.TOKENS,
    PageCodesEnum.MGM,
    PageCodesEnum.EXPORTS,
    PageCodesEnum.APPROVAL_TREE,
    PageCodesEnum.STAFF_LIST,
    PageCodesEnum.GOOGLE_MAP,
    // PageCodesEnum.LUCKY_DRAW,
    PageCodesEnum.WALLET_SERVICES,
    PageCodesEnum.ACTION_TYPES,
    PageCodesEnum.DFU_ACCESS,
    PageCodesEnum.DFI_ACCESS,
    PageCodesEnum.DFI_MOSAIC,
    PageCodesEnum.WSS,
    PageCodesEnum.NOTIFICATIONS,
    PageCodesEnum.TAX,
    PageCodesEnum.AMR,
    PageCodesEnum.FORMS_REPORT,
    PageCodesEnum.TOOLS,
    PageCodesEnum.WOMS_ACCOUNT_TYPES,
    PageCodesEnum.COUPONS,
    PageCodesEnum.APP_VERSION,
    PageCodesEnum.COUPON_SIGNUP_REWARDS,
    PageCodesEnum.HOME_POPUP
  ]
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import zone.js/dist/zone-error;  // Included with Angular CLI.
