import {
  Deadline,
  PublicAccount,
	MultisigCosignatoryModification,
	MultisigCosignatoryModificationType,
	ModifyMultisigAccountTransaction,
} from 'tsjs-xpx-chain-sdk';
import { CATAPULT_CONSTANTS } from '../../../constants/catapult.constants';
import { Injectable } from '@angular/core';

@Injectable()
export class MultisignModificationBuilder {
  private _cosigner: MultisigCosignatoryModification[];
	private _minApproval: number;
	private _minRemoval: number;

  constructor() {
    console.log('Hello CatapultProvider Provider');
  }

  // Cosigner
  setCosigners(cosigner: string[]) {
    this._cosigner = cosigner.map(cosign => {
			const pubAccount = PublicAccount.createFromPublicKey(cosign, CATAPULT_CONSTANTS.networkType);
			return new MultisigCosignatoryModification(MultisigCosignatoryModificationType.Add, pubAccount);
		});
    return this;
	}

  get getCosigners(): MultisigCosignatoryModification[] {
    return this._cosigner;
  }

  // Minimum Approval
  setMinApproval(minApproval: number) {
    this._minApproval = minApproval;
    return this;
	}

  get minApproval(): number {
    return this._minApproval;
	}

	// Minimum Approval
  setMinRemoval(minRemoval: number) {
    this._minRemoval = minRemoval;
    return this;
	}

  get minRemoval(): number {
    return this._minRemoval;
  }

	build(): ModifyMultisigAccountTransaction {
		return ModifyMultisigAccountTransaction.create(Deadline.create(), this._minApproval, this._minRemoval, this._cosigner, CATAPULT_CONSTANTS.networkType);
	}
}
