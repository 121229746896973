import { Injectable } from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {
  Account,
  AccountHttp,
  Deadline,
  Listener,
  LockFundsTransaction,
  NetworkType,
  Transaction,
  TransactionHttp,
  UInt64,
  SignedTransaction,
} from 'tsjs-xpx-chain-sdk';
import { MosaicUtil } from '../../../models/catapult/util/mosaic.util';
import * as config from '../../../../../config.json';
import {timeout} from 'q';
import { CATAPULT_CONSTANTS } from '../../../constants/catapult.constants';
import { Http } from '@angular/http';
import { AESUtility } from '../../../providers/utilities/aes.utility';

@Injectable()
export class TransactionUtility {
  private continue = true;
  constructor(private accountHttp: AccountHttp, private transactionHttp: TransactionHttp, private http: Http) {
  }

  async getConfirmedTransactionByHash(hash: string) {
    this.continue = true;
    return new Promise((resolve, reject) => {
      this.createInterval(async () => {
        await this.transactionHttp.getTransactionStatus(hash).toPromise().then(res => {
        console.log("TCL: TransactionUtility -> getConfirmedTransactionByHash -> res", res)
          if(['confirmed', 'partial'].includes(res.group)) {
            this.continue = false;
            resolve(true);
          } else if (res.group === 'failed') {
            this.continue = false;
            resolve(false);
          }
        }).catch(err => console.log(err));
      });
    });
  }

  private createInterval(callBack: Function) {
    if (this.continue) {
      setTimeout( async () => {
        await callBack();
        this.createInterval(callBack);
      }, 5000);
    }
  }

  signAndAnnounce(privateKey: string, transaction: Transaction) {
    const account = Account.createFromPrivateKey(privateKey, NetworkType[config.catapult.networkType]);
    const signedTransaction = account.sign(transaction, config.catapult.generationHash);
    console.log('TCL: TransactionUtility -> signAndAnnounce -> signedTransaction', signedTransaction);
		this.transactionHttp.announce(signedTransaction);
		return signedTransaction;
  }

  signAndAnnounceAggregateBondedInterval(privateKey: string, transaction: Transaction) {
    const cosigner = Account.createFromPrivateKey(privateKey, NetworkType[config.catapult.networkType]);
    const signedTransaction = cosigner.sign(transaction, config.catapult.generationHash);
    console.log('TCL: TransactionUtility -> signAndAnnounceAggregateBondedInterval -> signedTransaction', signedTransaction);

    const lockFundsTransaction = LockFundsTransaction.create(
      Deadline.create(),
      MosaicUtil.createMosaicByHexId(config.catapult.lockFundMosaic.hexId),
      UInt64.fromUint(480),
      signedTransaction,
      NetworkType[config.catapult.networkType]
    );
    const lockFundsTransactionSigned = cosigner.sign(lockFundsTransaction, config.catapult.generationHash);

    this.transactionHttp.announce(lockFundsTransactionSigned).toPromise().then(() => {
      return this.getConfirmedTransactionByHash(lockFundsTransactionSigned.hash);
    }).then(() => {
      this.transactionHttp.announceAggregateBonded(signedTransaction);
    });
  }

  signAndAnnounceAggregateBonded(privateKey: string, transaction: Transaction) {
    const cosigner = Account.createFromPrivateKey(privateKey, NetworkType[config.catapult.networkType]);
    const signedTransaction = cosigner.sign(transaction, config.catapult.generationHash);

    const lockFundsTransaction = LockFundsTransaction.create(
      Deadline.create(),
      MosaicUtil.createMosaicByHexId(config.catapult.lockFundMosaic.hexId),
      UInt64.fromUint(480),
      signedTransaction,
      NetworkType[config.catapult.networkType]
    );
    const lockFundsTransactionSigned = cosigner.sign(lockFundsTransaction, config.catapult.generationHash);

    let listener = new Listener(config.catapult.wsNodeUrl, WebSocket);
    listener.open().then(() => {
      this.transactionHttp.announce(lockFundsTransactionSigned);

      listener.confirmed(cosigner.address).pipe(
        // @ts-ignore
        filter(transaction => transaction.transactionInfo !== undefined && transaction.transactionInfo.hash === lockFundsTransactionSigned.hash),
        mergeMap(ignored => this.transactionHttp.announceAggregateBonded(signedTransaction))
      ).toPromise().then(announcedAggregateBonded => {
        this.transactionHttp.announceAggregateBonded(signedTransaction);

        listener.close();
        listener = null;
      }).catch(err => {
				console.log('TCL: TransactionUtility -> signAndAnnounceAggregateBonded -> err', err);
      });
    });
  }

  signAndAnnounceAggregateBonded1(privateKey: string, transaction: Transaction) {
    const account = Account.createFromPrivateKey(privateKey, NetworkType[config.catapult.networkType]);
    const signedTransaction = account.sign(transaction, config.catapult.generationHash);
    return this.transactionHttp.announceAggregateBonded(signedTransaction);
  }

  signAndAnnounceLockFundsInterval(privateKey: string, transaction: Transaction): Promise<SignedTransaction> {
    const cosigner = Account.createFromPrivateKey(privateKey, NetworkType[config.catapult.networkType]);
    const signedTransaction: SignedTransaction = cosigner.sign(transaction, config.catapult.generationHash);

    const lockFundsTransaction = LockFundsTransaction.create(
      Deadline.create(),
      MosaicUtil.createMosaicByHexId(config.catapult.lockFundMosaic.hexId),
      UInt64.fromUint(480),
      signedTransaction,
      NetworkType[config.catapult.networkType],
      UInt64.fromUint(0)
    );
    const lockFundsTransactionSigned = cosigner.sign(lockFundsTransaction, config.catapult.generationHash);

    return new Promise((resolve, reject) => {
      this.transactionHttp.announce(lockFundsTransactionSigned).toPromise().then(() => {
        return this.getConfirmedTransactionByHash(lockFundsTransactionSigned.hash);
      }).then(() => {
        resolve(signedTransaction);
      }).catch(err => {
        reject('Error: signAndAnnounceLockFunds -> ' + err);
      });
    });
  }

  announceTransactionInterval(signedTransaction: SignedTransaction) {
    return this.transactionHttp.announceAggregateBonded(signedTransaction).toPromise().then(async (res) => {
      console.log('transactionHttp.announceAggregateBonded',res);
      return await this.getConfirmedTransactionByHash(signedTransaction.hash);
    }).catch(err => {
      console.log('Error: signAndAnnounceLockFunds -> ', err);
    });
  }

  async signedAndAnnounceAggragateComplete(privateKey: string, transaction: Transaction): Promise<string>{
		const cosigner = Account.createFromPrivateKey(privateKey, CATAPULT_CONSTANTS.networkType);
		const signedAcT = cosigner.sign(transaction, config.catapult.generationHash);
    console.log("TCL: TransactionUtility -> signedAndAnnounceAggragateComplete -> signedAcT", signedAcT)
    
		////////////////////// call API here
    this.transactionHttp.announce(signedAcT).toPromise();
    return signedAcT.hash;
		/* return this.post('/', signedAcT).then((response:any) => {
			console.log("TCL: TransactionUtility -> response", response)
      // console.log("TCL: TransactionUtility -> JSON.parse(response._body).hash", JSON.parse(response._body).hash);
			return signedAcT.hash;
		}); */
  }

  async post(endpoint1, data, server = 'autosign_api') {
    const nData 				= { endpoint: endpoint1, method: "POST", data: data, server};
    const encryptedData = { data: AESUtility.encrypt(JSON.stringify(nData)) };
    // console.log("TCL: HttpJumpProvider -> post -> endpoint1", endpoint1, '<----------------->', JSON.stringify(encryptedData));
    return this.http.post(`${config.api.host}/CSyYLvo18ni6BU38qUjxWeyaiP4rpn3VC492rt2yKqbY`, encryptedData).toPromise().then((response: any) => {
      console.log('HttpJumpProvider -> post -> response', response)
      return response;
      // return { _body: AESUtility.decrypt(response._body) };
    });
  }
}
