import { Injectable } from '@angular/core';
import { Mosaic, MosaicId } from 'tsjs-xpx-chain-sdk';
import { UInt64 } from 'tsjs-xpx-chain-sdk/dist/src/model/UInt64';
import { DecimalPipe } from '@angular/common';
import * as config from '../../../../../config.json';
import { DefaultMosaic } from '../../../models/catapult/mosaic/defaultMosaic';

/*
  Generated class for the Utilities provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MosaicUtility {

  constructor() {
    console.log('Hello MosaicUtil Provider');
  }

  public getDefaultMosaics(): DefaultMosaic[] {
    return <DefaultMosaic[]>config.catapult.mosaics;
  }

  public hexToMosaic(hexId: string, amount: number, divisibility: number): Mosaic {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.hexId === hexId);
    const divisibleAmount = this.getRelativeAmount(amount, divisibility);
    const uint64Amount = UInt64.fromUint(divisibleAmount);

    return new Mosaic(new MosaicId(mosaic.hexId), uint64Amount);
  }

  public createMosaic(name: string, amount: number = 0): Mosaic {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.name === name);
    const divisibleAmount = this.getRelativeAmount(amount, mosaic.divisibility);
    const uint64Amount = UInt64.fromUint(divisibleAmount);

    return new Mosaic(new MosaicId(mosaic.hexId), uint64Amount);
  }

  public createMosaicByHexId(hexId: string, amount: number = 0): Mosaic {
    const uint64Amount = UInt64.fromUint(amount);

    return new Mosaic(new MosaicId(hexId), uint64Amount);
  }

  public getMosaicIdByName(mosaicName: string): MosaicId {
    const mosaic = this.getMosaicInfoByName(mosaicName);
    return new MosaicId(mosaic.hexId);
  }

  public getMosaicHexIdByName(mosaicName: string) {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.name === mosaicName);
    return mosaic.hexId;
  }

  public getMosaicIdByname(mosaicName: string) {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.name === mosaicName);
    return new MosaicId(mosaic.hexId);
  }

  public getMosaicInfoByName(mosaicName: string): DefaultMosaic {
    return config.catapult.mosaics.find(mosaic => mosaic.name === mosaicName);
  }

  public getMosaicInfoByHexId(hexId: string): DefaultMosaic {
    return config.catapult.mosaics.find(mosaic => mosaic.hexId === hexId);
  }

  public getCurrency(mosaicHexId: string, toUpperCase: boolean = false): string {
    const mosaic = config.catapult.mosaics.find(m => m.hexId === mosaicHexId);
    return !toUpperCase ? mosaic.name : mosaic.name.toUpperCase();
  }

  public getRelativeAmount(amount: number, divisibility: number): number {
    const divisibleAmount = Math.pow(10, divisibility);
    return amount * divisibleAmount;
  }

  public getRelativeAmountByMosaic(mosaic: Mosaic): number {
    const mosaicInfo = this.getMosaicInfoByHexId(mosaic.id.toHex());
    return mosaic.amount.compact() * Math.pow(10, mosaicInfo.divisibility);
  }

  public getRelativeAmountFormatted(amount: number, divisibility: number): string {
    const decimal = new DecimalPipe(undefined);
    const relativeAmount= this.getRelativeAmount(amount, divisibility);
    return decimal.transform(relativeAmount, '1.1-2');
  }

  public getDivisibleAmount(amount: number, divisibility: number): number {
    const divisibleAmount = Math.pow(10, divisibility);
    return amount / divisibleAmount;
  }

  public getDivisibleAmountFormatted(amount: number, divisibility: number): string {
    const decimal = new DecimalPipe(undefined);
    const divisibleAmount= this.getDivisibleAmount(amount, divisibility);
    return decimal.transform(divisibleAmount, '1.1-2');
  }
}
