import { Injectable } from '@angular/core';
import {
  Deadline,
  InnerTransaction,
  NetworkType,
  PublicAccount,
  Transaction,
  TransferTransaction,
  UInt64,
} from 'tsjs-xpx-chain-sdk';

import {
  AggregateTransaction
} from 'tsjs-xpx-chain-sdk/dist/src/model/transaction/AggregateTransaction';

import * as config from '../../../../../config.json';
import { ChronoUnit } from 'js-joda';

/*
  Generated class for the CatapultProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MultisigTransactionBuilder {
  private _cosigner: PublicAccount;
  private _multisigAccount: PublicAccount;

  private _originalTransactions: Transaction[];
  private _dummyTransactions: TransferTransaction[];
  private _feeTransactions: TransferTransaction[];
  private _manualSignTransactions: TransferTransaction[];

  constructor() {
    console.log('Hello CatapultProvider Provider');
  }


  // Cosigner
  setCosigner(cosigner: PublicAccount) {
    this._cosigner = cosigner;
    return this;
  }
  get getCosigner(): PublicAccount {
    return this._cosigner;
  }

  // Multisig Account
  setMultisigAccount(multisigAccount: PublicAccount) {
    this._multisigAccount = multisigAccount;
    return this;
  }
  get getMultisigAccount(): PublicAccount {
    return this._multisigAccount;
  }

  // Original transfer transactions
  setOriginalTransactions(transferTransactions: Transaction[]) {
    this._originalTransactions = transferTransactions;
    return this;
  }
  get originalTransactions() {
    return this._originalTransactions || [];
  }

  // Dummy transfer transactions
  setDummyTransactions(dummyTransactions: TransferTransaction[]) {
    this._dummyTransactions = dummyTransactions;
    return this;
  }
  get dummyTransactions() {
    return this._dummyTransactions || [];
  }

  // Transfer Transaction for fees
  setFeeTransactions(feeTransactions: TransferTransaction[]) {
    this._feeTransactions = feeTransactions;
    return this;
  }
  get feeTransactions() {
    return this._feeTransactions || [];
  }

  // Transfer Transaction for manual signing like Director/s or Manager/s
  setManualSignTransactions(manualSignTransactions: TransferTransaction[]) {
    this._manualSignTransactions = manualSignTransactions;
    return this;
  }
  get manualSignTransactions() {
    return this._manualSignTransactions || [];
  }

  private sign(transaction: Transaction, signer: PublicAccount): InnerTransaction {
    return transaction.toAggregate(signer);
  }

  build(): AggregateTransaction {
    const originalInnerTransactions   = this.originalTransactions.map(transaction => this.sign(transaction, this._multisigAccount));
    const dummyInnerTransactions      = this.dummyTransactions.map(transaction => this.sign(transaction, this._cosigner));
    const feeInnerTransactions        = this.feeTransactions.map(transaction => this.sign(transaction, this._multisigAccount));
    const manualSignTransactions      = this.manualSignTransactions.map(transaction => this.sign(transaction, this._cosigner));
    const innerTransactions           = [ ...originalInnerTransactions, ...dummyInnerTransactions, ...feeInnerTransactions, ...manualSignTransactions ];

    const networkType = NetworkType[config.catapult.networkType];

    return AggregateTransaction.createBonded(Deadline.create(), innerTransactions, networkType, [], UInt64.fromUint(0));
  }

  buildAggregateComplete(): AggregateTransaction {
    const originalInnerTransactions   = this.originalTransactions.map(transaction => this.sign(transaction, this._multisigAccount));
    const dummyInnerTransactions      = this.dummyTransactions.map(transaction => this.sign(transaction, this._cosigner));
    const feeInnerTransactions        = this.feeTransactions.map(transaction => this.sign(transaction, this._multisigAccount));
    const manualSignTransactions      = this.manualSignTransactions.map(transaction => this.sign(transaction, this._cosigner));
    const innerTransactions           = [ ...originalInnerTransactions, ...dummyInnerTransactions, ...feeInnerTransactions, ...manualSignTransactions ];

    const networkType = NetworkType[config.catapult.networkType];

    return AggregateTransaction.createComplete(Deadline.create(12, ChronoUnit.HOURS), innerTransactions, networkType, [], UInt64.fromUint(0));
  }
}
