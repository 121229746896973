import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { GlobalConfig } from 'src/app/core/config/global.config';
import { HttpClient } from '@angular/common/http';
import { FeeType } from 'src/app/models/fee/fee-type.model';
import { DefinitionRule } from 'src/app/models/fee/definition.model';
import { AccountType } from 'src/app/models/fee/account-type.model';
import { TransferType } from 'src/app/models/fee/transfer-type.model';
import { Calculation } from 'src/app/models/fee/calculation.model';
import { FeeUserType } from 'src/app/models/fee/user-type.model';
import { IndustryType } from 'src/app/models/fee/industry.model';
// import { BaseService } from '../encryption';
import { Fxxx } from 'src/app/models/fee/export.model';
import { KYCCreate, KYCDetails } from 'src/app/models/kyc/kyc.model';
import { CurrencyModel } from 'src/app/models/currency.model';

import * as config from './../../../../config.json';
import { MosaicType } from 'src/app/models/fee/mosaics.model.js';

@Injectable()
export class APIProvider {

    FEE_DEFINITION = '/fee-table/fee-definition';
    TRANSFER_TYPE = '/fee-table/transfer-type';
    ACCOUNT_TYPE = '/fee-table/account-type';
    FEE_TYPE = '/fee-table/fee-type';
    CALCULATION = '/fee-table/fee-calculation';
    USER_TYPE = '/fee-table/user-type';
    INDUSTRY = '/fee-table/industry';
    EXPORT = '/fee-table/export';
    EXPORT_ACTIVE = '/fee-table/export/set-active';
    EXPORT_TEST = '/fee-table/export/set-test';
    KYC = '/kyc';
    KYC_STATUS = '/kyc/update-status';
    CURRENCY = '/currency';
    TERM_TABLE = '/term-table';
    DIRECTORS_PASSWORD = '/director-password';
    AUTHFACTORS = '/authfactors/recovery';
    MOSAICS = '/fee-table/mosaics';
    TIER = '/fee-table/tier';
    KYC_TIER = '/kyc/tier';
    PAY_TOKEN = '/fee-table/paytoken';
    MGM = '/fee-table/mgm';
    MLFEE = '/fee-table/mlfee';
    FEE_TYPE_V2 = '/fee-table/fee-type-v2';
    KEY_PROVIDERS = '/key-provider';

    AFS1_URL = `${GlobalConfig.API_URL}/authfactors/afs1`;
    AFS2_URL = `${GlobalConfig.API_URL}/authfactors/afs2`;

    constructor(private httpClient: HttpClient) {}

    async get(endpoint) {
        const result = this.httpClient.get(GlobalConfig.ADD_API_URL(endpoint))
        .toPromise()
        .then(res => {
            return res;
        });
        return await Promise.resolve(result);
    }

    async post(endpoint, data) {
        const result = this.httpClient.post(GlobalConfig.ADD_API_URL(endpoint), data)
        .toPromise()
        .then((res) => {
            return res;
        });
        return await Promise.resolve(result);
    }

    async delete(endpoint, id) {
        const result = this.httpClient.delete(GlobalConfig.ADD_API_URL(endpoint + '/' + id))
        .toPromise()
        .then((res) => {
            return res;
        });
        return await Promise.resolve(result);
    }

    async put(endpoint, data) {
        const result = this.httpClient.put(GlobalConfig.ADD_API_URL(endpoint), data)
        .toPromise()
        .then((res) => {
            return res;
        });
        return await Promise.resolve(result);
    }

    getFeetypes() {
        return this.get(this.FEE_TYPE).then((res: FeeType[]) => {
            res.sort((a, b) => {
                if (a.code > b.code) {
                    return 1;
                }
                if (a.code < b.code) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getDefinitions() {
        return this.get(this.FEE_DEFINITION).then((res: DefinitionRule[]) => {
            res.sort((a, b) => {
                if (a.rx > b.rx) {
                    return 1;
                }
                if (a.rx < b.rx) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getAccountTypes() {
        return this.get(this.ACCOUNT_TYPE).then((res: AccountType[]) => {
            res.sort((a, b) => {
                if (a.code > b.code) {
                    return 1;
                }
                if (a.code < b.code) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getTransferTypes() {
        return this.get(this.TRANSFER_TYPE).then((res: TransferType[]) => {
            res.sort((a, b) => {
                if (a.code > b.code) {
                    return 1;
                }
                if (a.code < b.code) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getCalculations() {
        return this.get(this.CALCULATION).then((res: Calculation[]) => {
            return res;
        });
    }

    getUserTypes() {
        return this.get(this.USER_TYPE).then((res: FeeUserType[]) => {
            return res;
        });
    }

    getIndustries() {
        return this.get(this.INDUSTRY).then((res: IndustryType[]) => {
            res.sort((a, b) => {
                if (a.code > b.code) {
                    return 1;
                }
                if (a.code < b.code) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getSubIndustry(parentId) {
        return this.get(`/fee-table/industry/${parentId}/sub`).then((res: any[]) => {
            return res;
        });
    }

    getExports() {
        return this.get(this.EXPORT).then((res: Fxxx[]) => {
            return res;
        });
    }

    getFeetype() {
        return this.get(this.FEE_TYPE_V2).then((res: any[]) => {
            res.sort((a, b) => {
                if (a.creation > b.creation) {
                    return 1;
                }
                if (a.creation < b.creation) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    async getMLFee() {
        return this.get(this.MLFEE).then((res: any[]) => {
            res.sort((a, b) => {
                if (a.creation > b.creation) {
                    return 1;
                }
                if (a.creation < b.creation) {
                    return -1;
                }
                return 0;
            });
            return res;
        });
    }

    getMosaics() {
        return this.get(this.MOSAICS).then((res: MosaicType[]) => {
            return res;
        });
    }

    getTierForm() {
        return this.get(this.TIER).then((res: any) => {
            return res;
        });
    }

    getKYCTierForm() {
        return this.get(this.KYC_TIER).then((res: any) => {
            return res;
        });
    }

    getPayTokenForm() {
        return this.get(this.PAY_TOKEN).then((res: any) => {
            return res;
        });
    }

    getMGM() {
        return this.get(this.MGM).then((res: any) => {
            return res;
        });
    }

    getTier(id) {
        return this.get(this.TIER + id).then((res: any) => {
            return res;
        });
    }

    getKYC() {
        return this.get(this.KYC).then((res: KYCCreate[]) => {
            return res;
        });
    }

    getCurrencies() {
        const data = {
            'skip': 0,
            'limit': 0,
            'filters': { }
        };
        return this.post(this.CURRENCY + '/search', data).then((res: any) => {
            return res;
        });
    }

    getKYCDetails(username) {
        return this.get(`${this.KYC}/${username}`).then((res: KYCDetails) => {
            return res;
        });
    }

    getTerms() {
        const data = {
            'skip': 0,
            'limit': 0,
            'filters': { }
        };
        return this.post(this.TERM_TABLE + '/search', data).then((res) => {
            return res;
        });
    }

    getTermExport() {
        return this.get(this.TERM_TABLE + '/history').then((res) => {
            return res;
        });
    }

    getDirector() {
        return this.get(`${this.DIRECTORS_PASSWORD}`).then((res: any[]) => {
            return res;
        });
    }

    getRecoveryPW(data) {
        return this.post(this.AUTHFACTORS, data).then((res: any) => {
            return res;
        });
    }

    async getEncryptionAFS1(data) {
        const result = this.httpClient.post(`${this.AFS1_URL}/enc`, data)
        .toPromise()
        .then((res) => {
            return res;
        });
        return await Promise.resolve(result);
    }

    async getEncryptionAFS2(data) {
        const result = this.httpClient.post(`${this.AFS2_URL}/enc`, data)
        .toPromise()
        .then((res) => {
            return res;
        });
        return await Promise.resolve(result);
    }

    saveFeeType(feetype) {
        return this.post(this.FEE_TYPE, feetype).then((res) => {
            return res;
        });
    }

    saveUserType(user) {
        return this.post(this.USER_TYPE, user).then((res) => {
            return res;
        });
    }

    saveDefinition(txrx) {
        return this.post(this.FEE_DEFINITION, txrx).then((res) => {
            return res;
        });
    }

    saveCalculation(calc) {
        return this.post(this.CALCULATION, calc).then((res) => {
            return res;
        });
    }

    saveIndustry(industry) {
        return this.post(this.INDUSTRY, industry).then((res) => {
            return res;
        });
    }

    saveTransferType(transfer) {
        return this.post(this.TRANSFER_TYPE, transfer).then((res) => {
            return res;
        });
    }

    saveFxxx(fxxx) {
        return this.post(this.EXPORT, fxxx).then((res) => {
            return res;
        });
    }

    saveMLFee(fee) {
        return this.post(this.MLFEE, fee).then((res) => {
            return res;
        });
    }

    saveMosaic(data) {
        return this.post(this.MOSAICS, data).then((res) => {
            return res;
        });
    }

    saveTier(data) {
        return this.post(this.TIER, data).then((res) => {
            return res;
        });
    }

    saveKYCTier(data) {
        return this.post(this.KYC_TIER, data).then((res) => {
            return res;
        });
    }

    savePayToken(data) {
        return this.post(this.PAY_TOKEN, data).then((res) => {
            return res;
        });
    }

    saveMGM(data) {
        return this.post(this.MGM, data).then((res) => {
            return res;
        });
    }

    saveFeeTypes(feetype) {
        return this.post(this.FEE_TYPE_V2, feetype).then((res) => {
            return res;
        });
    }

    saveTerm(term) {
        return this.post(this.TERM_TABLE, term).then((res) => {
            return res;
        });
    }

    saveCurrency(currency) {
        return this.post(this.CURRENCY, currency).then((res) => {
            return res;
        });
    }

  updateCurrency(currency) {
    return this.put(this.CURRENCY, currency).then((res) => {
      return res;
    });
  }

    saveTermExport(term_export) {
        return this.post(this.TERM_TABLE + '/history', term_export).then((res) => {
            return res;
        });
    }

    saveLatestTermExport(id) {
        return this.put(this.TERM_TABLE + '/latest/' + id, {}).then((res) => {
            return res;
        });
    }

    updateFeeType(feetype) {
        return this.put(this.FEE_TYPE, feetype).then((res) => {
            return res;
        });
    }

    updateDefinition(txrx) {
        return this.put(this.FEE_DEFINITION, txrx).then((res) => {
            return res;
        });
    }

    updateTransferType(transfer) {
        return this.put(this.TRANSFER_TYPE, transfer).then((res) => {
            return res;
        });
    }

    updateIndustry(industry) {
        return this.put(this.INDUSTRY, industry).then((res) => {
            return res;
        });
    }

    updateAccountType(acc) {
        return this.put(this.ACCOUNT_TYPE, acc).then((res) => {
            return res;
        });
    }

    updateCode(code) {
        return this.put(this.EXPORT, code).then((res) => {
            return res;
        });
    }

    updateCalculation(calc) {
        return this.put(this.CALCULATION, calc).then((res) => {
            return res;
        });
    }

    updateUserType(user) {
        return this.put(this.USER_TYPE, user).then((res) => {
            return res;
        });
    }

    updateFxxxActive(id) {
        return this.put(`${this.EXPORT_ACTIVE}/${id}`, '').then((res) => {
            return res;
        });
    }

    updateFxxxTest(id) {
        return this.put(`${this.EXPORT_TEST}/${id}`, '').then((res) => {
            return res;
        });
    }

    updateMosaic(data) {
        return this.put(this.MOSAICS, data).then((res) => {
            return res;
        });
    }

    updateTierForm(data) {
        return this.put(this.TIER + '/formio', data).then((res) => {
            return res;
        });
    }

    updateTier(data) {
        return this.put(this.TIER, data).then((res) => {
            return res;
        });
    }

    updateMLFee(feetype) {
        return this.put(this.MLFEE, feetype).then((res) => {
            return res;
        });
    }

    updateFeeTypes(feetype) {
        return this.put(this.FEE_TYPE_V2, feetype).then((res) => {
            return res;
        });
    }

    updateMGM(data) {
        return this.put(this.MGM, data).then((res) => {
            return res;
        });
    }

    saveDirector(data) {
        return this.post(this.DIRECTORS_PASSWORD, data).then((res) => {
            return res;
        });
    }

    deleteFeeType(id) {
        return this.delete(this.FEE_TYPE, id).then( res => {
            return res;
        });
    }

    deleteDefinition(id) {
        return this.delete(this.FEE_DEFINITION, id).then( res => {
            return res;
        });
    }

    deleteAccountType(id) {
        return this.delete(this.ACCOUNT_TYPE, id).then( res => {
            return res;
        });
    }

    deleteCalculation(id) {
        return this.delete(this.CALCULATION, id).then( res => {
            return res;
        });
    }

    deleteIndustry(id) {
        return this.delete(this.INDUSTRY, id).then( res => {
            return res;
        });
    }

    deleteTransferType(id) {
        return this.delete(this.TRANSFER_TYPE, id).then( res => {
            return res;
        });
    }

    deleteUserType(id) {
        return this.delete(this.USER_TYPE, id).then( res => {
            return res;
        });
    }

    deleteCurrency(id) {
        return this.delete(this.CURRENCY, id).then( res => {
            return res;
        });
    }

    deleteTerm(id) {
        return this.delete(this.TERM_TABLE, id).then( res => {
            return res;
        });
    }

    deleteMLFee(id) {
        return this.delete(this.MLFEE, id).then( res => {
            return res;
        });
    }

    deleteMosaic(id) {
        return this.delete(this.MOSAICS, id).then( res => {
            return res;
        });
    }

    deleteFeeTypes(id) {
return this.delete(this.FEE_TYPE_V2, id).then( res => {
  return res;
});
}

deleteMGM(id) {
  return this.delete(this.MGM, id).then((res) => {
    return res;
  });
}
}
