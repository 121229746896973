import { Injectable } from '@angular/core';
import { TransferTransaction, PublicAccount, NetworkType, Account, AggregateTransaction, SimpleWallet, MultisigCosignatoryModification, MultisigCosignatoryModificationType, ModifyMultisigAccountTransaction, Deadline } from 'tsjs-xpx-chain-sdk';

import { Contact } from '../../../models/app/contact';
import { MosaicXX } from '../../../models/catapult/mosaic/mosaicXX';
import { TransactionXX } from '../../../models/catapult/transaction/transactionsXX';
import { TransferTransactionBuilder } from '../builder/transfer-transaction.builder';
import * as config from '../../../../../config.json';
import { MultisigTransactionBuilder } from '../builder/multisig-transaction.builder';
import { TransactionUtility } from '../utility/transaction.utility';
import { Observable } from 'rxjs';
import { CATAPULT_CONSTANTS } from 'src/app/constants/catapult.constants';

@Injectable()
export class MultisignTransfer {
  constructor(private transactionUtility: TransactionUtility) {}

  getTransactionXX() {
    const transactionsX: TransactionXX[] = [];
    transactionsX.push(new TransactionXX);

    // Recipient[0] Simulation
    transactionsX[0].recipient.appuser.username='John6677';
    transactionsX[0].recipient.appuser.accounts = 'VBJOCU7O3SK65DC2KWI2TSLJMOIKKKYFHY77JQA2';
    transactionsX[0].recipient.appusernDTO.parents=['alex1', 'linkDavid', 'JohnHan', 'Jamesho123'];
    transactionsX[0].recipient.appusernDTO.type='10-00-01'; // simulation input data

    transactionsX[0].chain='DF';
    transactionsX[0].txType= 'TY11';
    transactionsX[0].mosaics.push(new MosaicXX());
    transactionsX[0].mosaics[0].amount=0; // simulation input data
    transactionsX[0].mosaics[0].mosaicName='dragonfly.usd';
    transactionsX[0].mosaics.push(new MosaicXX());
    transactionsX[0].mosaics[1].amount=0;
    transactionsX[0].mosaics[1].mosaicName='dragonfly.khr';

    // Recipient[1] Simulation
    transactionsX.push(new TransactionXX);
    transactionsX[1].recipient.appuser.username='kwlee90';
    transactionsX[1].recipient.appuser.accounts = 'VDOQECXTLEHR6YEOFSIPP5OQDDTHMPITYRUAO6JC';
    transactionsX[1].recipient.appusernDTO.parents=['alexxx1', 'linkDavid55', 'JohnHankkk', 'Jamesho123zzz'];
    transactionsX[1].recipient.appusernDTO.type='11-00-02'; // simulation input data

    transactionsX[1].chain='DF';
    transactionsX[1].txType= 'TY22';
    transactionsX[1].mosaics.push(new MosaicXX());
    transactionsX[1].mosaics[0].amount=0;
    transactionsX[1].mosaics[0].mosaicName='dragonfly.usd';

    return transactionsX;
  }

  send(cosigner: Account, multisign: PublicAccount, recipient: Contact, mosaics: MosaicXX[], transacstionXX?: TransactionXX[]) {
    // Original transfer
    const originalTransferTxn: TransferTransaction = new TransferTransactionBuilder()
      .setRecipient(recipient)
      .setMosaics(mosaics)
      .setMessage('originalTransferTxn')
      .setTxType('TY22')
      .setChain('DF')
      .build();

    // Fee transfer
    const feeTransferTransaction: TransferTransaction[] = !transacstionXX ? [] : transacstionXX.map(txn => {
      return new TransferTransactionBuilder()
        .setRecipient(txn.recipient)
        .setMosaics(txn.mosaics)
        .setMessage('feeTransferTransaction')
        .setTxType(txn.txType)
        .setChain(txn.chain)
        .build();
    });

    // For autosign
    const autosign1 = PublicAccount.createFromPublicKey(config.catapult.approverParents.autosign[0], NetworkType[config.catapult.networkType]);
    const autosign1Txn: TransferTransaction = new TransferTransactionBuilder()
      .setMessage('autosign1Txn')
      .setRecipient(autosign1.address)
      .build();
    const autosign2 = PublicAccount.createFromPublicKey(config.catapult.approverParents.autosign[1], NetworkType[config.catapult.networkType]);
    const autosign2Txn: TransferTransaction = new TransferTransactionBuilder()
      .setMessage('autosign2Txn')
      .setRecipient(autosign2.address)
      .build();

    // For manual sign like Director/s or Manager/s
    const director1 = PublicAccount.createFromPublicKey(config.catapult.approverParents.director, NetworkType[config.catapult.networkType]);
    const manualSign1Txn: TransferTransaction = new TransferTransactionBuilder()
      .setMessage('manualSign1Txn')
      .setRecipient(director1.address)
      .build();

    const multisigTransaction: AggregateTransaction = new MultisigTransactionBuilder()
			.setCosigner(cosigner.publicAccount)
			.setMultisigAccount(multisign)
			.setFeeTransactions(feeTransferTransaction)
			.setOriginalTransactions([ originalTransferTxn ])
			.setDummyTransactions([ autosign1Txn, autosign2Txn ])
			.setManualSignTransactions([ manualSign1Txn ])
			.build();
		console.log('TCL: MultisignTransfer -> send -> multisigTransaction', multisigTransaction);

    this.transactionUtility.signAndAnnounceAggregateBondedInterval(cosigner.privateKey, multisigTransaction);
  }

  /**
   * Convert multisign account and make the current logged in wallet as cosigner
   * @param ownerPrivateKey The private key of current logged in wallet
   * @param multisigWallet
   * @param multisigPassword
   * @param pKeyA
   * @param pKeyB
   * @returns The multisig PublicAccount which contains public key and address
   */
  convertToMultisignAccount(
    parentPublicAccount: PublicAccount,
    modifierAccount: Account,
    cosigner: string[],
    type: MultisigCosignatoryModificationType
  ) {
    // 1. Create a Multisig SimpleWallet and Account
    /* const parentPublicAccount = Account.createFromPrivateKey(
      multisigWallet.open(multisigPassword).privateKey,
      this.networkType
    );
    console.log('convertAccountMultisig :: started', parentPublicAccount);
    console.log(
      'convertAccountMultisig :: started',
      parentPublicAccount.privateKey
    );

    // 2. Create the account of the owner of the wallet.
    const modifierAccount = Account.createFromPrivateKey(
      ownerPrivateKey,
      this.networkType
    ); */

    // 4. Convert cosigner public key array of string to MultisigCosignatoryModification
    const cosignersModification = cosigner.map(cosignerPubKey => {
      const pubAccount = PublicAccount.createFromPublicKey(
        cosignerPubKey,
        CATAPULT_CONSTANTS.networkType
      );

      return new MultisigCosignatoryModification(
        type,
        pubAccount
      );
    });
    console.log('convertAccountMultisig :: cosignersModification', cosignersModification);

    // 5. Prepare the convertIntoMultisigTransaction
    const convertIntoMultisigTransaction = ModifyMultisigAccountTransaction.create(
      Deadline.create(),
      0,
      0,
      cosignersModification,
      CATAPULT_CONSTANTS.networkType
    );

    const autosign1 = PublicAccount.createFromPublicKey(config.catapult.approverParents.autosign[0], NetworkType[config.catapult.networkType]);
    const autosign1Txn: TransferTransaction = new TransferTransactionBuilder()
      .setMessage('autosign1Txn')
      .setRecipient(autosign1.address)
      .build();
    const autosign2 = PublicAccount.createFromPublicKey(config.catapult.approverParents.autosign[1], NetworkType[config.catapult.networkType]);
    const autosign2Txn: TransferTransaction = new TransferTransactionBuilder()
      .setMessage('autosign2Txn')
      .setRecipient(autosign2.address)
      .build();

    const multisigTransaction: AggregateTransaction = new MultisigTransactionBuilder()
			.setCosigner(modifierAccount.publicAccount)
			.setMultisigAccount(parentPublicAccount)
			.setOriginalTransactions([ convertIntoMultisigTransaction ])
			.setDummyTransactions([ autosign1Txn, autosign2Txn ])
			.build();
		console.log('TCL: MultisignTransfer -> send -> multisigTransaction', multisigTransaction);

    this.transactionUtility.signAndAnnounceAggregateBondedInterval(modifierAccount.privateKey, multisigTransaction);
    /* console.log('convertAccountMultisig :: convertIntoMultisigTransaction', convertIntoMultisigTransaction);

    // 6. Sign the transaction with the multisig wallet.
    const signedTransaction = modifierAccount.sign(convertIntoMultisigTransaction);
    console.log('convertAccountMultisig :: signedTransaction', signedTransaction);

    return new Observable(observer => {

      this.createTransactionHttp().announce(signedTransaction).subscribe(tx => {
          console.log('convertAccountMultisig :: finished', tx.message);
          observer.next(parentPublicAccount);
        }, err => console.error('convertAccountMultisig :: error', err)
      );
    }); */
  }
}
