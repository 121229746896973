import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserAccountService } from '../../services/user-account.service';
import {AuthService} from './auth.service';

@Injectable({ providedIn: 'root' })
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(private authService: AuthService) {}

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
        console.log('authenticate : ', this.userIdentity);
    }

    getId() {
        return this.userIdentity.id;
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.role) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            // if (this.userIdentity.authorities.includes(authorities[i])) {
            //     return true;
            // }
            if (this.userIdentity.role === authorities[i]) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        /* if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        } */

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.authService.get().toPromise().then( account => {
                if (account) {
                    this.userIdentity = account;
                    this.authenticated = true;
                } else {
                    this.userIdentity = null;
                    this.authenticated = false;
                }
                this.authenticationState.next(this.userIdentity);
                return this.userIdentity;
            })
            .catch(err => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);
                return null;
            });
    }

    getIdentity() {
        return this.userIdentity;
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    removeIdentity() {
        this.userIdentity = undefined;
    }
}
