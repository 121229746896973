import {Injector, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {AuthExpiredInterceptor} from './auth-expired.interceptor';
import {CustomHttpInterceptor} from './custom-http.interceptor';

const providers = [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
    deps: [LocalStorageService, SessionStorageService]
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthExpiredInterceptor,
    multi: true,
    deps: [Injector]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true,
    deps: [Injector]
  }];

@NgModule({
  providers: providers
})
export class InterceptorModule { }
