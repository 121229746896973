import { Contact } from '../../app/contact';
import { MosaicXX } from '../mosaic/mosaicXX';

export class TransactionXX {
  recipient: Contact;
  message: string;
  mosaics: MosaicXX[] = [];
  txType: string;
  chain: string; // "DF", "AMC"

  constructor() {
      this.recipient = new Contact();
  }
}
