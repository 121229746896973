import {AES, SHA256, enc, pad} from 'crypto-js';
import * as config from '../../../../config.json';
import {Account, EncryptedMessage, NetworkType, PublicAccount} from 'tsjs-xpx-chain-sdk';
import { CATAPULT_CONSTANTS } from '../../constants/catapult.constants';

export class AESUtility {
  private static KEY = enc.Hex.parse('5ae1b8a17bad4da4fdac796f64c16ecd');
  private static IV = enc.Hex.parse('34857d973953e44afb49ea9d61104d8c');

  public static isSet = false;

  public static encrypt(content: string, iv: string = null, key: string = null): string {
    const encrypted = AES.encrypt(content, key ? enc.Hex.parse(key) : AESUtility.KEY, {
      iv: iv ? enc.Hex.parse(iv) : AESUtility.IV,
      padding: pad.ZeroPadding
    });
    return encrypted.ciphertext.toString(enc.Base64);
  }

  public static decrypt(cipherText: string, iv: string = null, key: string = null): string {
    const bytes = AES.decrypt(cipherText, key ? enc.Hex.parse(key) : AESUtility.KEY, {
      iv: iv ? enc.Hex.parse(iv) : AESUtility.IV,
      padding: pad.ZeroPadding
    });
    return bytes.toString(enc.Utf8);
  }

  public static decryptToObject<T = any>(cipherText: string, iv: string = null, key: string = null): T {
    return JSON.parse(AESUtility.decrypt(cipherText, iv, key)) as T;
  }

  public static encryptFromObject(obj: any, iv: string = null, key: string = null): string {
    return AESUtility.encrypt(JSON.stringify(obj), iv, key);
  }

  static refresh(payload: any, token: string, sirius: string) {
    const first =  sirius.substr(sirius.length - 10, sirius.length), last = token.substr(token.length - 10, token.length);
    const passKey = Account.createFromPrivateKey(SHA256(first + last).toString(), NetworkType[config.catapult.networkType]);
    const payloadString = EncryptedMessage.decrypt(EncryptedMessage.createFromPayload(payload), passKey.privateKey, passKey.publicAccount).payload;
    const payloadObject = JSON.parse(payloadString);
    this.KEY = enc.Hex.parse(payloadObject.key);
    this.IV = enc.Hex.parse(payloadObject.iv);
    this.isSet = true;
  }

  public static encryptMessage(senderPrivateKey: string, recipientPublicKey: string, text: string): EncryptedMessage {
		const recipientPublicAccount = PublicAccount.createFromPublicKey(recipientPublicKey, CATAPULT_CONSTANTS.networkType);
		return EncryptedMessage.create(text, recipientPublicAccount, senderPrivateKey);
	}
}
