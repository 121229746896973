import { TransferTransactionBuilder } from '../builder/transfer-transaction.builder';
import { MultisigTransactionBuilder } from '../builder/multisig-transaction.builder';
import { MetadataTransactionBuilder } from '../builder/metadata.builder';
import { MultisignModificationBuilder } from '../builder/multisign-modification.builder';

export const CATAPULT_BUILDER_PROVIDERS = [
	MetadataTransactionBuilder,
  TransferTransactionBuilder,
	MultisigTransactionBuilder,
	MultisignModificationBuilder
];
