import {Injectable, Injector} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable({ providedIn: 'root'})
export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            const authService: AuthService = this.injector.get(AuthService);
                            const router: Router = this.injector.get(Router);
                            authService.logout();
                            router.navigateByUrl('/account/login');
                        }
                    }
                }
            )
        );
    }
}
