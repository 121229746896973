import { Injectable } from '@angular/core';
import { Address, Deadline, Mosaic, NetworkType, PlainMessage, TransferTransaction } from 'tsjs-xpx-chain-sdk';

import * as config from '../../../../../config.json';
import { Contact } from '../../../models/app/contact';
import { MosaicXX } from '../../../models/catapult/mosaic/mosaicXX';
import { MosaicUtil } from '../../../models/catapult/util/mosaic.util';
import { TRANSACTION_MESSAGE_TYPE } from '../../../models/enums/transaction-message-type.enum';
import { TransactionMessage } from '../transaction/transaction-message';

@Injectable()
export class TransferTransactionBuilder {
  private _address:   Address;
  private _message:   string;
  private _mosaics:   Mosaic[];
  private _txType:    string;
  private _chain:     string;
  private _messageType: TRANSACTION_MESSAGE_TYPE;
  private _additionalData: 	{};

  constructor() {
  }

  // Recipient
  setRecipient(recipient: Contact | Address) {
    let address: Address = null;
    if (recipient instanceof Contact) {
      const contact: Contact = <Contact>recipient;
      address = Address.createFromRawAddress(contact.appuser.accounts);
    } else {
      address = recipient;
    }

    this._address = address;
    return this;
  }
  get recipient(): Address {
    return this._address;
  }

  // Message
  setMessage(msg: string) {
    this._message = msg;
    return this;
  }

  /**
   * Gets the stringified { txType: 'TY22', chain: 'DF } if there is no message added
   */
  get message(): string {
    return this._message;
  }

  // Tx type
  setTxType(txType: string) {
    this._txType = txType;
    return this;
  }
  get txType(): string {
    return this._txType;
  }

  // Tx type
  setChain(chain: string) {
    this._chain = chain;
    return this;
  }
  get chain(): string {
    return this._chain;
  }

  // Mosaics
  setMosaics(mosaicsXX: MosaicXX[]) {
    const mosaics = mosaicsXX.map(mosaic => MosaicUtil.createMosaic(mosaic.mosaicName, mosaic.amount));
    this._mosaics = mosaics;
    return this;
  }
  get mosaics(): Mosaic[] {
    return this._mosaics || [];
  }

  private buildMessage(): PlainMessage {
    const messageObject = { msg: this.message || '', txType: this.txType || '', chain: this.chain || '' };
    const plainMessage = PlainMessage.create(JSON.stringify(messageObject));
    return plainMessage;
  }

  private buildJSONMessage(): PlainMessage {
		const txnMessage: string = new TransactionMessage()
			.setTxType(this.txType)
			.setChain(this.chain)
			.setMessage(this.message)
			.setAdditionalInfo(this.additionalData)
			.buildJSON();

    return PlainMessage.create(txnMessage);
  }

  // Additional info
	setAdditionalData(additionalData: { }) {
		this._additionalData = additionalData;
		return this;
	}
	get additionalData(): any {
		return this._additionalData || {};
	}

  // Message
  setMessageType(messageType: TRANSACTION_MESSAGE_TYPE) {
    this._messageType = messageType;
    return this;
  }
  get messageType(): TRANSACTION_MESSAGE_TYPE {
    return this._messageType || TRANSACTION_MESSAGE_TYPE.JSON;
  }

  build(): TransferTransaction {
    const networkType = NetworkType[config.catapult.networkType];
    const plainMessage = this.messageType === TRANSACTION_MESSAGE_TYPE.JSON ? this.buildJSONMessage() : this.buildMessage();

    return TransferTransaction.create(Deadline.create(), this.recipient, this.mosaics, plainMessage, networkType);
  }
}
