import {Address, Deadline, UInt64} from 'tsjs-xpx-chain-sdk';
import {
  MetadataModification,
  MetadataModificationType,
  ModifyMetadataTransaction
} from 'tsjs-xpx-chain-sdk/dist/src/model/transaction/ModifyMetadataTransaction';
import {CATAPULT_CONSTANTS} from '../../../constants/catapult.constants';
import {ENCRYPTION_TYPE} from '../../../models/enums/encryption.enum';
import {Injectable} from '@angular/core';
import {AESUtility} from '../../../providers/utilities/aes.utility';

@Injectable()
export class MetadataTransactionBuilder {
	private _address: Address;
	private _fields: MetadataModification[];

	setAddress(address: Address) {
		this._address = address;
		return this;
	}

	get address(): Address {
		return this._address;
	}

	setFields(type: MetadataModificationType, data: Object, enc: ENCRYPTION_TYPE = ENCRYPTION_TYPE.PLAIN) {
		const fieldKeys = Object.keys(data);
		let fields: MetadataModification[] = fieldKeys.map(key => {
				if(enc === ENCRYPTION_TYPE.PLAIN) {
					return new MetadataModification(type, key, JSON.stringify(data[key]));
				} else {
					return new MetadataModification(type, key, AESUtility.encryptFromObject(data[key]));
				}
		});

		if(type === MetadataModificationType.REMOVE) fields = fieldKeys.map(key => new MetadataModification(type, key));

		this._fields = fields;
		return this;
	}

	get fields(): MetadataModification[] {
		return this._fields;
	}

	buildWithAddress(): ModifyMetadataTransaction {
		if(!this.address) throw Error('ERROR_NO_ADDRESS_IN_METADATA');
		return ModifyMetadataTransaction.createWithAddress(CATAPULT_CONSTANTS.networkType, Deadline.create(), this.address, this.fields, UInt64.fromUint(0) );
	}
}
