import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {AuthService} from './core/auth/auth.service';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {InputsModule, MDBBootstrapModule} from 'ng-uikit-pro-standard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APIProvider} from './providers/api/api';
import {ToastrModule} from 'ngx-toastr';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {InterceptorModule} from './core/interceptor/interceptor.module';
import {CATAPULT_PROVIDERS} from './shared/catapult';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // Framework
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    InputsModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    InterceptorModule,
    // Routing Modules
    AppRoutingModule
  ],
  providers: [
    AuthService,
    LocalStorageService,
    SessionStorageService,
    NgxSpinnerService,
    APIProvider,
    ...CATAPULT_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

