import {OAuth} from 'tsjs-xpx-chain-sdk/dist/src/infrastructure/model/models';

export class SiriusJwtProvider {
  private static token: string;

  public static get authorizationHeader() {
    return { Authorization: this.token };
  }
  public static get oAuth() {
    const oAuth = new OAuth();
    oAuth.accessToken = this.token;
    console.log("TCL: SiriusJwtProvider -> getoAuth -> oAuth", oAuth)
    return oAuth;
  }

  public static initialize(siriusToken: string) {
    console.log("TCL: SiriusJwtProvider -> initialize -> siriusToken", siriusToken)
    this.token = siriusToken;
  }
}
