import { CATAPULT_BUILDER_PROVIDERS } from './@indexes/builder.index';
import { CATAPULT_TRANSFER_PROVIDERS } from './@indexes/transfer.index';
import { CATAPULT_UTILITY_PROVIDERS } from './@indexes/utility.index';
import { CATAPULT_TRANSACTION_PROVIDERS } from './@indexes/transaction.index';

export const CATAPULT_PROVIDERS = [
  ...CATAPULT_UTILITY_PROVIDERS,
  ...CATAPULT_BUILDER_PROVIDERS,
  ...CATAPULT_TRANSACTION_PROVIDERS,
  ...CATAPULT_TRANSFER_PROVIDERS,
];
