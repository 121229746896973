import { Injectable } from '@angular/core';
import { TransferTransaction, PublicAccount, NetworkType, Account, AggregateTransaction, Deadline} from 'tsjs-xpx-chain-sdk';

import { TransferTransactionBuilder } from '../builder/transfer-transaction.builder';
import { MultisigTransactionBuilder } from '../builder/multisig-transaction.builder';
import { TransactionUtility } from '../utility/transaction.utility';
import { MosaicUtility } from '../utility/mosaic.utility';
import * as config from '../../../../../config.json';

@Injectable()
export class MosacFilterTransaction {
  constructor(
    private transferBuilder: TransferTransactionBuilder,
    private transactionUtility: TransactionUtility,
    private mosaicUtility: MosaicUtility) {}


  /* mosaicFilterTransaction(cosigner: Account, multisign: PublicAccount) {
		console.log('TCL: Mosac   FilterTransaction -> mosaicFilterTransaction -> mosaicFilterTransaction');
    // Original transfer
    const filters = this.mosaicUtility.getBlockedMosaicIdForModificationAll();
		console.log('TCL: MosacFilterTransaction -> mosaicFilterTransaction -> filters', filters);

    const mosaicFilterTx: ModifyAccountPropertyMosaicTransaction = AccountPropertyTransaction.createMosaicPropertyModificationTransaction(Deadline.create(), PropertyType.BlockMosaic, [...filters], NetworkType[config.catapult.networkType]);
		console.log('TCL: MosacFilterTransaction -> mosaicFilterTransaction -> mosaicFilterTx', mosaicFilterTx);

    // For autosign
    const autosign1Pub = config.catapult.approverParents.autosign[0];
    const autosign1 = PublicAccount.createFromPublicKey(autosign1Pub, NetworkType[config.catapult.networkType]);
    const autosign1Txn: TransferTransaction = this.transferBuilder
      .setMessage('autosign1Txn')
      .setRecipient(autosign1.address)
      .build();
    const autosign2Pub = config.catapult.approverParents.autosign[1];
    const autosign2 = PublicAccount.createFromPublicKey(autosign2Pub, NetworkType[config.catapult.networkType]);
    const autosign2Txn: TransferTransaction = this.transferBuilder
      .setMessage('autosign2Txn')
      .setRecipient(autosign2.address)
      .build();

    const multisigTransaction: AggregateTransaction = new MultisigTransactionBuilder()
      .setCosigner(cosigner.publicAccount)
      .setMultisigAccount(multisign)
      .setOriginalTransactions([ mosaicFilterTx ])
      .setDummyTransactions([ autosign1Txn, autosign2Txn ])
      .build();

    console.log('TCL: MosacFilterTransaction -> mosaicFilterTransaction -> multisigTransaction', multisigTransaction);

    this.transactionUtility.signAndAnnounceAggregateBonded(cosigner.privateKey, multisigTransaction);
  } */
}
