import {Injectable} from '@angular/core';
import {Account, AggregateTransaction, NetworkType, PublicAccount, TransferTransaction} from 'tsjs-xpx-chain-sdk';
import {TransferTransactionBuilder} from '../builder/transfer-transaction.builder';
import {MultisigTransactionBuilder} from '../builder/multisig-transaction.builder';
import {TransactionUtility} from '../utility/transaction.utility';
import {ModifyMetadataTransaction} from 'tsjs-xpx-chain-sdk/dist/src/model/transaction/ModifyMetadataTransaction';
import * as config from '../../../../../config.json';
import { TRANSACTION_MESSAGE_TYPE } from '../../../models/enums/transaction-message-type.enum';

@Injectable()
export class MetadataTransaction {
  // @ts-ignore
  private readonly AUTOSIGN_1_PUBLIC_KEY = config.catapult.approverParents.autosign[0];
  // @ts-ignore
  private readonly AUTOSIGN_2_PUBLIC_KEY = config.catapult.approverParents.autosign[1];
  // @ts-ignore
  private readonly DIRECTOR_PUBLIC_KEY = config.catapult.approverParents.director;
  // @ts-ignore
  private readonly MANAGER_PUBLIC_KEY = config.catapult.approverParents.manager;
  // @ts-ignore
  private readonly STAFF_PUBLIC_KEY = config.catapult.approverParents.staff;
  constructor(private transactionUtility: TransactionUtility) {}


  private static createPublicAccount(publicKey: string): PublicAccount {
    return PublicAccount.createFromPublicKey(publicKey, NetworkType[config.catapult.networkType]);
  }

  // For triggering partial
  private static buildTransferTransaction(publicAccount: PublicAccount, message: string): TransferTransaction {
    return new TransferTransactionBuilder()
      .setMessage(message)
      .setRecipient(publicAccount.address)
      .build();
  }

  async sendMetadataComplete(cosigner: Account, multisign: PublicAccount, metadataDetails: ModifyMetadataTransaction) {

    const multisigTransaction: AggregateTransaction = new MultisigTransactionBuilder()
			.setCosigner(cosigner.publicAccount)
			.setMultisigAccount(multisign)
			.setOriginalTransactions([ metadataDetails ])
			.buildAggregateComplete();
		console.log("TCL: MultisignTransfer -> send -> multisigTransaction", multisigTransaction)

    return this.transactionUtility.signedAndAnnounceAggragateComplete(cosigner.privateKey, multisigTransaction);
  }

  buildAggregateMetadataTransaction(cosigner: Account, multisign: PublicAccount, metadataDetails: ModifyMetadataTransaction, initiatorUsername?: string, message?: string) {
    const autoSign1Transaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.AUTOSIGN_1_PUBLIC_KEY), 'autoSign1Txn');
    const autoSign2Transaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.AUTOSIGN_2_PUBLIC_KEY), 'autoSign2Txn');

    // For manual sign like Director/s or Manager/s or Staff
    const directorTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.DIRECTOR_PUBLIC_KEY), 'directorTxn');
    // const managerTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.MANAGER_PUBLIC_KEY), 'managerTxn');
    // const staffTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.STAFF_PUBLIC_KEY), 'staffTxn');
    let dummyMetadataDetails: TransferTransaction;
    if(message) {
      dummyMetadataDetails = new TransferTransactionBuilder()
        .setRecipient(multisign.address)
        .setMosaics([])
        .setMessageType(TRANSACTION_MESSAGE_TYPE.JSON)
        .setMessage('metadataModification')
        .setAdditionalData({ from: initiatorUsername, to: 'modifyMultisign', message })
        .build();
    }
    // const dummyMetadataDetails = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(metadataDetails.signer.publicKey), message);

    return new MultisigTransactionBuilder()
      .setCosigner(cosigner.publicAccount)
      .setMultisigAccount(multisign)
      .setOriginalTransactions([ metadataDetails ])
      .setDummyTransactions(
        message ? [ autoSign1Transaction, autoSign2Transaction, dummyMetadataDetails ] : [ autoSign1Transaction, autoSign2Transaction ]
        )
      .setManualSignTransactions([directorTransaction ])
      .build();
  }

  buildAggregateMetadataTransactionComplete(cosigner: Account, multisign: PublicAccount, metadataDetails: ModifyMetadataTransaction, message?: string) {
    const autoSign1Transaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.AUTOSIGN_1_PUBLIC_KEY), 'autoSign1Txn');
    const autoSign2Transaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.AUTOSIGN_2_PUBLIC_KEY), 'autoSign2Txn');

    // For manual sign like Director/s or Manager/s or Staff
    // const directorTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.DIRECTOR_PUBLIC_KEY), 'directorTxn');
    // const managerTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.MANAGER_PUBLIC_KEY), 'managerTxn');
    // const staffTransaction = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(this.STAFF_PUBLIC_KEY), 'staffTxn');
    let dummyMetadataDetails: TransferTransaction;
    if(message) {
      dummyMetadataDetails = new TransferTransactionBuilder()
        .setRecipient(metadataDetails.signer.address)
        .setMosaics([])
        .setMessageType(TRANSACTION_MESSAGE_TYPE.JSON)
        .setMessage('metadataModification')
        .setAdditionalData({message})
        .build();
    }
    // const dummyMetadataDetails = MetadataTransaction.buildTransferTransaction(MetadataTransaction.createPublicAccount(metadataDetails.signer.publicKey), message);

    return new MultisigTransactionBuilder()
      .setCosigner(cosigner.publicAccount)
      .setMultisigAccount(multisign)
      .setOriginalTransactions([ metadataDetails ])
      .setDummyTransactions(
        message ? [ autoSign1Transaction, autoSign2Transaction, dummyMetadataDetails ] : [ autoSign1Transaction, autoSign2Transaction ]
        )
      // .setManualSignTransactions([directorTransaction ])
      .buildAggregateComplete();
  }
}


