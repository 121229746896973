import { AppUser } from './AppUser';
import { AppUsernDTO } from './app-user.dto';

export class Contact {
  appuser: AppUser;
  appusernDTO: AppUsernDTO;

  constructor() {
    this.appuser      =  new AppUser();
    this.appusernDTO  =  new AppUsernDTO();
  }
}
