import { KYCStatusEnum } from './kycenum.dto';
import { WalletUserStatus } from './wallet-user-enum.dto';
import { BaseModel } from './base-model';

export class AppUsernDTO extends BaseModel {
  username: string;
  status: string;
  tier: string;
  publicKey: string;
  kycStatus: string;
  kycLevel: string;
  organizationEntity: string;
  dataZ: string;
  type: string;
  imageHash: string;
  parents: string[];
  otp: { otp: string; type: string };
  creationDate?: string;
  mobile?: string;
  email?: string;
  amrP: string[];
}
