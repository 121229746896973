import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Principal} from './principal';
import * as config from '../../../../config.json';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService implements CanActivate {
    constructor(
        private router: Router,
        private principal: Principal
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
      const authorities = route.data['authorities'];
      console.log("TCL: UserRouteAccessService -> route.data", route.data)
      if (!config.auth.required) {
        return true;
      }

      const names = environment.modules;
      if (route.data['code'] && !([].concat.apply([], names) as string[]).some(code => code === route.data['code'])) {
        this.router.navigateByUrl('/account/login');
        return false;
      }
        // console.log(route, environment.modules.);
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    }

    checkLogin(authorities: string[], url: string): Promise<boolean> {
        const principal = this.principal;
        return Promise.resolve(
            principal.identity().then(account => {
                if (account && authorities) {
                    return principal.hasAnyAuthority(authorities).then(response => {
                        if (response) {
                            return true;
                        }
                        if (isDevMode()) {
                            console.error('User has not any of required authorities: ', authorities);
                            // this.toastr.warning('Invalid Credentials. Please login to continue');
                            this.router.navigateByUrl('/account/login');
                        }
                        return false;
                    });
                } else if (account) {
                    return true;
                }
                // this.toastr.warning('Invalid Credentials. Please login to continue');
                this.router.navigateByUrl('/account/login');
                return false;
            })
        );
    }
}
