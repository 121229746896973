export class MosaicXX {
  mosaicName: string;
  amount: any;
  percentage?: any;

  setName(name: string) {
    this.mosaicName = name;
    return this;
  }

  setAmount(amount: number) {
    this.amount = amount;
    return this;
  }

  build(): MosaicXX {
    const mosaicXX = new MosaicXX();
    mosaicXX.mosaicName = this.mosaicName;
    mosaicXX.amount = this.amount;
    return mosaicXX;
  }
}
