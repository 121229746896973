import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginModel} from '../../models/users/login.model';
import {GlobalConfig} from '../config/global.config';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {AESUtility} from '../../providers/utilities/aes.utility';

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient, private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {
  }

  login(loginModel: LoginModel) {
    const server = loginModel.server == 'odoo' ? '' : '/vtiger'
    return this.httpClient.post(GlobalConfig.ADD_AUTH_URL('/operations' + server) , loginModel , { observe: 'body' })
                          .pipe(map(authenticateSuccess.bind(this)));
    function authenticateSuccess(response) {
      if (response.success) {
        // this.localStorage.set('private_key', loginModel.key);
        this.storeAuthenticationToken(response, loginModel.rememberMe);
        return true;
      }
      return false;
    }
  }

  async loginViaToken(token) {
    const payload = JSON.parse(atob(token));
    const data = {
      token: payload.payload,
      siriusToken: payload.siriusToken,
      payloadToken: payload.payloadToken
    }
    this.storeAuthenticationToken(data, false);
    return true;
  }

  get() {
    return this.httpClient.get(GlobalConfig.ADD_AUTH_URL('/operations'));
  }

  storeAuthenticationToken({token, siriusToken, payloadToken}, rememberMe) {
    AESUtility.refresh(payloadToken, token, siriusToken);
    if (rememberMe) {
      this.localStorage.set('_at', token);
      this.localStorage.set('_pt', payloadToken);
      this.localStorage.set('_st', siriusToken);
    } else {
      this.sessionStorage.set('_at', token);
      this.sessionStorage.set('_pt', payloadToken);
      this.sessionStorage.set('_st', siriusToken);
    }
  }

  logout(): Observable<any> {
    return new Observable(observer => {
      this.localStorage.remove('_at');
      this.sessionStorage.remove('_at');
      this.localStorage.remove('_st');
      this.sessionStorage.remove('_st');
      this.localStorage.remove('_pt');
      this.sessionStorage.remove('_pt');
      AESUtility.isSet = false;
      observer.next();
    });
  }
}
