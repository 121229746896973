import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AESUtility} from '../../providers/utilities/aes.utility';
import {SiriusJwtProvider} from '../../providers/sirius/sirius-jwt.provider';

@Injectable({ providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localStorage.get('_at') || this.sessionStorage.get('_at');
    const sirius = this.localStorage.get('_st') || this.sessionStorage.get('_st');
    if (!AESUtility.isSet && token) {
      const payload = this.localStorage.get('_pt') || this.sessionStorage.get('_pt');
      const sirius = this.localStorage.get('_st') || this.sessionStorage.get('_st');
      SiriusJwtProvider.initialize(sirius);
      AESUtility.refresh(payload, token, sirius);
    }
    if (!!token) {
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token,
                SiriusToken: 'Bearer ' + sirius
            }
        });
    }
    return next.handle(request);
  }
}
