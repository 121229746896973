export class AppUser {
  id: number;

  indez: string = 'appuser';
  language: string = '';
  nickname: string = '';
  username: string = '';
  mobile: string = '';
  otp: string = '';
  mobile2: string = '';

  otp2: string = '';
  email: string = '';
  deviceid: string = '';
  photo: string = '';
  accounts: string = '';

  enabled: number = 1;
  privacy: number = 0;
  notification: number = 1;

  createdate: string = '';
  updatedate: string = '';

  password: string = '';
  salt: string = '';

  longpw: string = '';

  jwtConsumerId: any;

  constructor() {}

  set(au: AppUser) {
    this.id = au.id;
    this.language = au.language;
    this.nickname = au.username;
    this.username = au.username;
    this.mobile = au.mobile;
    this.otp = au.otp;
    this.mobile2 = au.mobile2;
    this.otp2 = au.otp2;
    this.email = au.email;
    this.deviceid = au.deviceid;
    this.photo = au.photo;
    this.accounts = au.accounts;
    this.enabled = au.enabled;
    this.privacy = au.privacy;
    this.notification = au.notification;
    this.createdate = au.createdate;
    this.updatedate = au.updatedate;
    this.password = au.password;
    this.salt = au.salt;
    this.longpw = au.longpw;
  }

  setlist(
    aid,
    alanguage,
    anickname,
    ausername,
    amobile,
    aotp,
    amobile2,
    aotp2,
    aemail,
    adeviceid,
    aphoto,
    aaccounts,
    aenabled,
    aprivacy,
    anotification,
    acreatedate,
    aupdatedate,
    apassword,
    asalt,
    alongpw
  ) {
    this.id = aid;
    this.language = alanguage;
    this.nickname = ausername;
    this.username = ausername;
    this.mobile = amobile;
    this.otp = aotp;
    this.mobile2 = amobile2;
    this.otp2 = aotp2;
    this.email = aemail;
    this.deviceid = adeviceid;
    this.photo = aphoto;
    this.accounts = aaccounts;
    this.enabled = aenabled;
    this.privacy = aprivacy;
    this.notification = anotification;
    this.createdate = acreatedate;
    this.updatedate = aupdatedate;
    this.password = apassword;
    this.salt = asalt;
    this.longpw = alongpw;
  }

  getlist(): any {
    const rtn = [
      this.indez,
      this.language,
      this.nickname,
      this.username,
      this.mobile,
      this.otp,
      this.mobile2,
      this.otp2,
      this.email,
      this.deviceid,
      this.photo,
      this.accounts,
      this.enabled,
      this.privacy,
      this.notification,
      this.createdate,
      this.updatedate,
      this.password,
      this.salt,
      this.longpw
    ];
    return rtn;
  }
}
