import { Mosaic, MosaicId, UInt64 } from 'tsjs-xpx-chain-sdk';
import { DefaultMosaic } from '../mosaic/defaultMosaic';
import * as config from '../../../../../config.json';
export class MosaicUtil {
  public static getDefaultMosaics(): DefaultMosaic[] {
    return config.catapult.mosaics as DefaultMosaic[];
  }

  public static hexIdToMosaic(hexId: string, amount: number, divisibility: number): Mosaic {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.hexId === hexId);
    const divisibleAmount = MosaicUtil.getRelativeAmount(amount, divisibility);
    const uint64Amount = UInt64.fromUint(divisibleAmount);

    return new Mosaic(new MosaicId(mosaic.hexId), uint64Amount);
  }

  public static createMosaic(name: string, amount: number = 0): Mosaic {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.name === name);
    const divisibleAmount = MosaicUtil.getRelativeAmount(amount, mosaic.divisibility);
    const uint64Amount = UInt64.fromUint(divisibleAmount);

    return new Mosaic(new MosaicId(mosaic.hexId), uint64Amount);
  }

  public static createMosaicByHexId(hexId: string): Mosaic {
    const uint64Amount = UInt64.fromUint(0);
    return new Mosaic(new MosaicId(hexId), uint64Amount);
  }

  public static getMosaicIdByName(mosaicName: string): MosaicId {
    const mosaic = this.getMosaicInfoByName(mosaicName);
    return new MosaicId(mosaic.hexId);
  }

  public static getMosaicHexIdByName(mosaicName: string) {
    const mosaic = config.catapult.mosaics.find(mosaic => mosaic.name === mosaicName);
    return mosaic.hexId;
  }

  public static getMosaicInfoByName(mosaicName: string) {
    return config.catapult.mosaics.find(mosaic => mosaic.name === mosaicName);
  }

  public static getMosaicInfoByHexId(hexId: string) {
    return config.catapult.mosaics.find(mosaic => mosaic.hexId === hexId);
  }

  public static getCurrency(mosaicHexId: string, toUpperCase: boolean = false): string {
    const mosaic = config.catapult.mosaics.find(m => m.hexId === mosaicHexId);
    return !toUpperCase ? mosaic.name : mosaic.name.toUpperCase();
  }

  public static getRelativeAmount(amount: number, divisibility: number): number {
    const divisibleAmount = Math.pow(10, divisibility);
    return amount * divisibleAmount;
  }

 /*  public static getRelativeAmountFormatted(amount: number, divisibility: number): string {
    const decimal = new DecimalPipe(undefined);
    const relativeAmount = this.getRelativeAmount(amount, divisibility);
    return decimal.transform(relativeAmount, '1.1-2');
  } */

  public static getDivisibleAmount(amount: number, divisibility: number): number {
    const divisibleAmount = Math.pow(10, divisibility);
    return amount / divisibleAmount;
  }

  /* public static getDivisibleAmountFormatted(amount: number, divisibility: number): string {
    const decimal = new DecimalPipe(undefined);
    const divisibleAmount= this.getDivisibleAmount(amount, divisibility);
    return decimal.transform(divisibleAmount, '1.1-2');
  } */
}
