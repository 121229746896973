import {Injectable} from '@angular/core';
import {MosaicId, NamespaceId} from 'tsjs-xpx-chain-sdk';
import {MetadataHttp} from 'tsjs-xpx-chain-sdk/dist/src/infrastructure/MetadataHttp';
import {AddressMetadata} from 'tsjs-xpx-chain-sdk/dist/src/model/metadata/AddressMetadata';
import {NamespaceMetadata} from 'tsjs-xpx-chain-sdk/dist/src/model/metadata/NamespaceMetadata';
import {MosaicMetadata} from 'tsjs-xpx-chain-sdk/dist/src/model/metadata/MosaicMetadata';
import * as config from '../../../../../config.json';

@Injectable()
export class MetadataUtility {
	constructor(private metadataHttp: MetadataHttp) {
		// @ts-ignore
	}

	getMetadataByAddress(plainAddress: string): Promise<AddressMetadata> {
		return new Promise((resolve, reject) => {
			this.metadataHttp.getAccountMetadata(plainAddress).toPromise()
				.then(metadata => resolve(metadata))
				.catch(err => resolve(null));
		});
	}

	getMetadataByNamespace(namespace: string): Promise<NamespaceMetadata> {
		const namespaceId = new NamespaceId(namespace);
    console.log(namespaceId);
    return new Promise((resolve, reject) => {
			this.metadataHttp.getNamespaceMetadata(namespaceId).toPromise()
				.then(metadata => resolve(metadata))
				.catch(err => resolve(null));
		});
	}

	getMetadataByMosaicHexId(hexId: string): Promise<MosaicMetadata> {
		const mosaicId = new MosaicId(hexId);
		return new Promise((resolve, reject) => {
			this.metadataHttp.getMosaicMetadata(mosaicId).toPromise()
				.then(metadata => resolve(metadata))
				.catch(err => resolve(null));
		});
	}
}
