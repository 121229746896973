export enum PageCodesEnum {
  TEST_SUBMISSION = 'TEST_SUBMISSION',
  SUBMISSIONS_DETAIL = 'SUBMISSIONS_DETAIL',
  USER_WALLET_MANAGEMENT = 'USER_WALLET_MANAGEMENT',
  SUBMISSIONS = 'SUBMISSIONS',
  AUTH_FACTOR_RECOVERY = 'AUTH_FACTOR_RECOVERY',
  FIXED_DEPOSIT_RATES = 'FIXED_DEPOSIT_RATES',
  SCREENING_SUBMISSIONS = 'SCREENING_SUBMISSIONS',
  FIXED_DEPOSIT_HISTORY = 'FIXED_DEPOSIT_HISTORY',
  FIXED_DEPOSIT_LIST = 'FIXED_DEPOSIT_LIST',
  FIXED_DEPOSIT_DETAIL = 'FIXED_DEPOSIT_DETAIL',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
  DIRECTORS_PASSWORD = 'DIRECTORS_PASSWORD',
  ENVIRONMENT_SETTINGS = 'ENVIRONMENT_SETTINGS',
  EXCHANGE = 'EXCHANGE',
  ADDRESSES = 'ADDRESSES',
  EXPORTS = 'EXPORTS',
  MGM = 'MGM',
  TOKENS = 'TOKENS',
  TIER = 'TIER',
  ACTION_TYPES = 'ACTION_TYPES',
  USER_CC = 'USER_CC',
  USER_BB = 'USER_BB',
  USER_AA = 'USER_AA',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  CALCULATION = 'CALCULATION',
  MULTI_LEVEL_FEE = 'MULTI_LEVEL_FEE',
  FEE_TYPE = 'FEE_TYPE',
  TRANSFER_TYPE = 'TRANSFER_TYPE',
  TABLE_DEFINE_FEE = 'TABLE_DEFINE_FEE',
  RATES = 'RATES',
  CURRENCY = 'CURRENCY',
  DFI_MOSAIC = 'DFI_MOSAIC',
  KYC_LEVELS = 'KYC_LEVELS',
  KYC = 'KYC',
  KYC_SUBMISSIONS = 'KYC_SUBMISSIONS',
  DEVELOPMENT = 'DEVELOPMENT',
  DEVELOPMENT_FORMS = 'DEVELOPMENT_FORMS',
  SCREENING_FORMS = 'SCREENING_FORMS',
  KYC_FORMS = 'KYC_FORMS',
  FORMS = 'FORMS',
  WALLET_DETAILS = 'WALLET_DETAILS',
  FEES = 'FEES',
  APPROVAL_TREE = 'APPROVAL_TREE',
  STAFF_LIST = 'STAFF_LIST',
  WHITELIST = 'WHITELIST',
  BLACKLIST = 'BLACKLIST',
  INTERNAL = 'INTERNAL',
  GOOGLE_MAP = 'GOOGLE_MAP',
  LUCKY_DRAW = 'LUCKY_DRAW',
  RECOVERY = 'RECOVERY',
  WALLET_SERVICES = 'WALLET_SERVICES',
  DFU_ACCESS = 'DFU_ACCESS',
  DFI_ACCESS = 'DFI_ACCESS',
  WSS = 'WSS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  TOOLS = 'TOOLS',
  TAX = 'TAX',
  AMR = 'AMR',
  WOMS_ACCOUNT_TYPES = 'WOMS_ACCOUNT_TYPES',
  FORMS_REPORT = 'FORMS_REPORT',
  COUPONS = 'COUPONS',
  APP_VERSION = 'APP_VERSION',
  HOME_POPUP = 'HOME_POPUP',
  COUPON_SIGNUP_REWARDS = 'COUPON_SIGNUP_REWARDS'
}
